import Rubikium_logo from "../assets/rubikium-logo.svg";
import { NavLink } from "../components/NavLink";
import { DarkModeToggle } from "./DarkModeToggle";
import { NavBar } from "./NavBar";

export const Header: React.FC = () => {
  return (
    <header className="w-full bg-slate-200 lg:pl-[calc(100vw-100%)] dark:bg-slate-800">
      <div className="mx-auto flex max-w-screen-lg flex-wrap justify-between gap-4 p-4">
        <div className="flex min-w-min flex-1 justify-between align-middle">
          <NavLink to="/" className="min-w-max text-lg/6">
            <Rubikium_logo
              className="mr-2 inline size-6"
              aria-label="Rubikium's logo"
            />
            <span className="font-bold text-slate-900 dark:text-slate-100">
              Rubikium
            </span>
          </NavLink>
          <DarkModeToggle />
        </div>
        <NavBar />
      </div>
    </header>
  );
};
