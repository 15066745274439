import {
  NavLink as _NavLink,
  NavLinkProps as _NavLinkProps,
  NavLinkRenderProps,
} from "react-router-dom";
import cn from "classnames";

export const NavLink: React.FC<_NavLinkProps> = ({
  children,
  className,
  ...props
}) => {
  let newClassName = (p: NavLinkRenderProps) => {
    if (typeof className === "function") {
      return cn(className(p), {
        "text-green-800 dark:text-green-300": !p.isActive,
      });
    } else {
      return cn(className, {
        "text-green-800 dark:text-green-300": !p.isActive,
      });
    }
  };
  return (
    <_NavLink className={newClassName} {...props}>
      {children}
    </_NavLink>
  );
};
