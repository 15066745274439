import { useEffect } from "react";
import { Link } from "../components/Link";

export const NotFoundPage: React.FC = () => {
  useEffect(() => {
    document.title = "Page not found - Rubikium's website";
  }, []);
  return (
    <div className="flex h-screen w-full flex-col items-center justify-center">
      <div className="text-slate-600">404 | Not Found</div>
      <Link to="/">Return to Home Page</Link>
    </div>
  );
};
