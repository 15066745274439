import { createBrowserRouter } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { NotFoundPage } from "./pages/NotFoundPage";

export const Router = createBrowserRouter([
  {
    path: "*",
    element: <NotFoundPage />,
  },
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "resume",
    lazy: async () => {
      const { ResumePage } = await import("./pages/ResumePage");
      return { element: <ResumePage /> };
    },
  },
  {
    path: "projects",
    lazy: async () => {
      const { ProjectsPage } = await import("./pages/ProjectsPage");
      return { element: <ProjectsPage /> };
    },
  },
]);
