import { useState } from "react";

const darkModeClass = "dark";
const lightModeClass = "light";
const themeKey = "theme";

export const useCurrentTheme = () => {
  const getStoredTheme = () => {
    if (
      localStorage.theme === "dark" ||
      (!("theme" in localStorage) &&
        window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
      return "dark";
    } else {
      return "light";
    }
  };
  const [currentTheme, setCurrentTheme] = useState(getStoredTheme);

  return {
    currentTheme,
    switchTheme: () => {
      const newTheme =
        currentTheme == darkModeClass ? lightModeClass : darkModeClass;

      setCurrentTheme(newTheme);
      window.localStorage.setItem(themeKey, newTheme);

      const classList = document.documentElement.classList;
      if (newTheme == darkModeClass) {
        classList.add(darkModeClass);
      } else {
        classList.remove(darkModeClass);
      }
    },
  };
};

export const DarkModeToggle: React.FC = () => {
  const { switchTheme } = useCurrentTheme();

  return (
    <button
      onClick={switchTheme}
      aria-label="Switch Theme"
      className="-m-2 p-2"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        className="size-6"
      >
        <title>Switch Theme</title>
        <g className="fill-transparent dark:fill-yellow-300">
          <circle r="4" cx="12" cy="12" />
          <g
            className="stroke-transparent dark:stroke-yellow-300"
            strokeLinecap="round"
            strokeWidth="1.5"
          >
            <line x1="12" x2="12" y1="3" y2="5" />
            <line x1="12" x2="12" y1="19" y2="21" />
            <line x1="3" x2="5" y1="12" y2="12" />
            <line x1="19" x2="21" y1="12" y2="12" />
            <line x1="5.65" x2="7" y1="5.65" y2="7" />
            <line x1="18.35" x2="17" y1="18.35" y2="17" />
            <line x1="5.65" x2="7" y1="18.35" y2="17" />
            <line x1="18.35" x2="17" y1="5.65" y2="7" />
          </g>
        </g>
        <g className="fill-yellow-500 dark:fill-transparent">
          <path d="M9.528 1.718a.75.75 0 0 1 .162.819A8.97 8.97 0 0 0 9 6a9 9 0 0 0 9 9 8.97 8.97 0 0 0 3.463-.69.75.75 0 0 1 .981.98 10.503 10.503 0 0 1-9.694 6.46c-5.799 0-10.5-4.7-10.5-10.5 0-4.368 2.667-8.112 6.46-9.694a.75.75 0 0 1 .818.162Z" />
        </g>
      </svg>
    </button>
  );
};
