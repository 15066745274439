import rubikium_avatar from "../assets/rubikium-avatar.png";
import { CommonPage } from "../components/CommonPage";

export const HomePage: React.FC = () => {
  return (
    <CommonPage title="Rubikium's website">
      <div className="flex flex-col justify-center">
        <img
          src={rubikium_avatar}
          className="mx-auto h-96 max-h-[min(50vh,100vw)] object-contain"
          alt="Rubikium's avatar"
        />
        <div className="mx-auto max-w-lg text-balance">
          <p className="mb-2 text-center">
            BEng in Computer Science, University of Hong Kong
          </p>
          <p className="mb-2 text-center">
            Interest in web and mobile applications,{" "}
            <span className="text-nowrap">UI/UX</span> design, CMS, and computer
            animation
          </p>
        </div>
      </div>
    </CommonPage>
  );
};
