import { Link as _Link, LinkProps as _LinkProps } from "react-router-dom";
import cn from "classnames";

type LinkProps = _LinkProps & {
  isExternal?: boolean;
};

export const Link: React.FC<LinkProps> = ({
  children,
  className,
  isExternal,
  ...props
}) => {
  return (
    <_Link
      className={cn(
        className,
        "font-medium text-green-800 underline dark:text-green-300"
      )}
      target={isExternal ? "_blank" : undefined}
      {...props}
    >
      {children}
    </_Link>
  );
};
